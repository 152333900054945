import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import './Nominate.css'
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import { isEmpty } from "lodash";

const Nominate = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiNominates {
        nodes {
          ISRIDBoardposition
          NominateBgcolor
          Verification
          emailaddress
          para
          rationalforyournominaton
          yourname
          nominateperson
          BoardPosition {
            Member_at_Large_3_
            President
            Secretary
            Treasurer
            Vice_President
          }
          nominatedynamic {
            TitleAlignment
            TitleFontColor
            TitleFontFamily
            TitleFontSize
            TitleFontWeight
            TitleName
          }
        }
      }
    }
  `)

  var IsridNominate = data.allStrapiNominates.nodes
  var IsridNominateDynamic = IsridNominate[0].nominatedynamic[0]
  const star = '*'


  const initialFormData = Object.freeze({
    name: '',
    emailAddress: '',
    nominateperson: '',
    ISRIDBoardposition: '',
    rationalforyournominaton: '',

  })
  const [formData, updateFormData] = React.useState(initialFormData)
  const handleChange = e => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    })
  }

  function nominatefun(){
    var templateParams = {
      name: formData.name,
      emailAddress: formData.emailAddress,
      nominateperson: formData.nominateperson,
      ISRIDBoardposition : formData.ISRIDBoardposition,
      rationalforyournomination : formData.rationalforyournominaton,
    }
    console.log(templateParams)

    if(isEmpty(templateParams.name)==false && isEmpty(templateParams.emailAddress)==false && isEmpty(templateParams.nominateperson)==false && isEmpty(templateParams.ISRIDBoardposition)==false){
    emailjs.send('service_dplwkbj','template_7fmjdje',templateParams,'user_32otrDt64WxDm2h4yXuVH')
          .then(function(result) {
            console.log('SUCCESS!', result.status, result.text);
            Swal.fire({
              icon: 'success',
              title: 'Thank you Nomination!',
              text: 'We will get back to you as soon as possible',
              confirmButtonText:'Okay!',
              confirmButtonColor: '#00c851',
            }).then(response => {
              /* Read more about isConfirmed, isDenied below */
              if (response.isConfirmed) {
                window.location.pathname="/home"
              } 
            })
          },
          function(error) {
          console.log('FAILED...', error);
          }
        );
  }
  else{
    alert('Please fill the form')
  }
  }
  return (
      <div class="card" style={{backgroundColor:IsridNominate[0].NominateBgcolor}}>
          <h2
            class="NominateTitle"
            style={{
              fontFamily: IsridNominateDynamic.TitleFontFamily,
              fontSize: IsridNominateDynamic.TitleFontSize,
              fontWeight: IsridNominateDynamic.TitleFontWeight,
              textAlign: IsridNominateDynamic.TitleAlignment,
              color: IsridNominateDynamic.TitleFontColor,
            }}
          >
            {IsridNominateDynamic.TitleName}
          </h2>
          <br/>
          <p><ReactMarkdown source={IsridNominate[0].para} /></p>
          <div>
            {/************************** */}
            <label htmlFor="firstname">
              {' '}
              <b>{IsridNominate[0].yourname}</b>
              <span className="star">{star}</span>&nbsp;
            </label>
            &nbsp;
            <input
              type="text"
              class="form-control"
              placeholder="Enter Your Name"
              name="name"
              required
              onChange={handleChange}
            />
            <br />
          {/************************** */}
          <label htmlFor="email">
                {' '}
                <b>{IsridNominate[0].emailaddress}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
                type="email"
                class="form-control"
                placeholder="Enter your email address"
                class="form-control"
                name="emailAddress"
                required
                onChange={handleChange}
                minlength="2"
                maxlength="50"
              />
              <br/>
              {/************************** */}
              <label htmlFor="nominateperson">
                {' '}
                <b>{IsridNominate[0].nominateperson}</b>
                <span className="star">{star}</span>
              </label>
              <br />
              <input
              type="text"
              class="form-control"
              placeholder="Enter the Nominate Person name"
              name="nominateperson"
              required
              onChange={handleChange}
            />
            <br />
            {/************************** */}
            <label htmlFor="ISRIDBoardposition">
                {' '}
                <b>{IsridNominate[0].ISRIDBoardposition}</b>
                <span className="star">{star}</span>
              </label>
            <select
            class="form-control"
            placeholder="Enter "
            name="ISRIDBoardposition"
            required
            onChange={handleChange}
            >
            <option hidden></option>
            <option value={IsridNominate[0].BoardPosition.President }>{IsridNominate[0].BoardPosition.President }</option>
            <option value={IsridNominate[0].BoardPosition.Vice_President }>{IsridNominate[0].BoardPosition.Vice_President }</option>
            <option value={IsridNominate[0].BoardPosition.Secretary }>{IsridNominate[0].BoardPosition.Secretary }</option>
            <option value={IsridNominate[0].BoardPosition.Treasurer }>{IsridNominate[0].BoardPosition.Treasurer }</option>
            <option value={IsridNominate[0].BoardPosition.Member_at_Large_3_ }>{IsridNominate[0].BoardPosition.Member_at_Large_3_ }</option>
            </select>
            <br />
            {/************************** */}
            <label htmlFor="rationalforyournominaton">
                {' '}
                <b>{IsridNominate[0].rationalforyournominaton}</b>
            </label>
            <br />
            <textarea 
            onChange={handleChange}
            id="rationalforyournominaton" 
            class="form-control" 
            name="rationalforyournominaton" 
            rows="6" cols="100"/>
            <br/>
            <br />
            <p>{IsridNominate[0].Verification}</p>
            <br/>
            <div className="text-center mt-4">
            <button type="submit" class="btn btn-success nominatebtn" onClick={nominatefun}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"35%",}}>Submit</button>
            </div>
          </div>
      </div>
  )
}

export default Nominate

